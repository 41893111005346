.step1Container {
    .modalBuyTokenContainer {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
