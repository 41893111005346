@import "../../assets/scss/base.scss";

.signup-page {
  width: 100%;
  height: 100vh;

  background-image: url(../../assets/img/home.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100vh;

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(16, 18, 34, 1) 100%);
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .signup-area {
    width: 450px;
    height: fit-content;
    padding: 50px;

    max-height: 100vh;
    overflow-y: auto;

    background-color: white;
    border-radius: 5px;

    z-index: 2;

    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 0 20px;
      padding: 20px;
    }

    .field + .field {
      margin-top: 22px;
    }

    label {
      display: block;
      margin-bottom: 4px;
    }

    button.main {
      margin-top: 30px;
    }

    .error {
      color: red;
    }

    .signup-logo {
      text-align: center;
      margin-bottom: 50px;
      cursor: pointer;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 500px) {
        margin-bottom: 30px;
      }

      img {
        width: 70px;

        @media screen and (max-width: 500px) {
          width: 55px;
        }
      }

      h2.title {
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 0;

        @media screen and (max-width: 500px) {
          font-size: 1.2rem;
        }
      }
    }

    .link-to-login {
      text-align: center;
      margin-top: 30px;

      span {
        color: $primary-color;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .gender {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
