.buyTokenContainer {
}

.centerMessageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
}
