.term-header {
  width: 100%;
  height: fit-content;

  background-image: url(../../assets/img/home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  padding-bottom: 50px;

  position: relative;

  .overlay {
    width: 100%;
    height: 100%;

    position: absolute;
    inset: 0;
    z-index: 1;

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(16, 18, 34, 1) 100%);
  }

  .home-menu {
    position: relative;
    z-index: 5;

    .container {
      width: 100%;
      max-width: 1200px;
      padding: 0 30px;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-top: 50px;

      // @media screen and (max-width: 1500px) {
      //   padding-top: 105px;
      // }

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .menu {
        ul {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          margin-bottom: 0;

          li {
            margin: 0 15px;

            a {
              text-decoration: none;
              color: whitesmoke;
              font-weight: 600;
              font-size: 1rem;
              cursor: pointer;
              transition: all 0.25s ease;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      .action {
        button {
          border: none;
          outline: none;
          padding: 8px 22px;
          background: linear-gradient($primary-color-light 0%, $primary-color 100%);
          box-shadow: $button-shadow 2px 2px 20px -5px;
          border-radius: 100px;
          color: whitesmoke;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.25s ease;

          &:hover {
            box-shadow: $button-shadow 2px 2px 30px -5px;
            background: linear-gradient($primary-color 0%, $primary-color-light 100%);
          }
        }

        button + button {
          margin-left: 20px;
        }
      }
    }
  }

  .home-menu-mobile {
    position: relative;
    z-index: 5;

    .container {
      width: 100%;
      padding: 0 30px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding-top: 20px;

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .drawer {
        color: $primary-color;
        font-size: 25px;
      }
    }
  }

  .term {
    margin-top: 50px;
    position: relative;
    z-index: 90;

    .container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;

      background-color: rgba(255, 255, 255, 0.9);
      padding: 50px;
      border-radius: 20px;

      @media screen and (max-width: 992px) {
        padding: 20px;
        border-radius: 10px;
      }
    }
  }
}

.term-footer {
  background-color: #141939;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    color: whitesmoke;

    .item1 {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100px;
        margin-bottom: 20px;
      }

      span {
        display: inline-block;
        font-size: 12px;
      }
    }

    .item2 {
      @media screen and (max-width: 576px) {
        margin-top: 30px;
        text-align: center;
      }

      span {
        display: block;
        margin-bottom: 12px;
      }
    }

    .item3 {
      a {
        display: inline-block;
        margin-bottom: 12px;
        text-decoration: none;
        color: $primary-color;

        @media screen and (max-width: 450px) {
          display: block;
        }
      }

      @media screen and (max-width: 576px) {
        margin-top: 30px;
        text-align: center;
      }

      a + a {
        margin-left: 22px;

        @media screen and (max-width: 450px) {
          margin-left: 0;
        }
      }
    }

    .item4 {
      @media screen and (max-width: 576px) {
        margin-top: 20px;
      }

      i {
        width: 50px;
        height: 50px;
        background-color: whitesmoke;
        color: #101222;
        border-radius: 50%;
        text-align: center;
        font-size: 22px;
        line-height: 50px;
        cursor: pointer;
        transition: all 0.25s ease;

        @media screen and (max-width: 992px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
        }

        &:hover {
          background-color: $primary-color;
          color: whitesmoke;
        }
      }

      a {
        display: inline-block;
      }

      a + a {
        margin-left: 10px;
      }
    }
  }

  .container2 {
    width: 100%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #101222;

    text-align: center;
    color: whitesmoke;
  }
}
