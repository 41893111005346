.home-header {
  width: 100%;
  height: 1000px;

  @media screen and (max-width: 1500px) {
    height: 800px;
  }

  @media screen and (max-width: 992px) {
    height: 900px;
  }

  @media screen and (max-width: 768px) {
    height: 750px;
  }

  @media screen and (max-width: 450px) {
    height: 700px;
  }

  background-image: url(../../assets/img/home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  &::after {
    content: "";
    width: 100%;
    height: 1000px;

    @media screen and (max-width: 1500px) {
      height: 800px;
    }

    @media screen and (max-width: 992px) {
      height: 900px;
    }

    @media screen and (max-width: 768px) {
      height: 750px;
    }

    @media screen and (max-width: 450px) {
      height: 700px;
    }

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(16, 18, 34, 1) 100%);
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  .home-menu {
    position: relative;
    z-index: 6;

    .container {
      width: 100%;
      max-width: 1400px;
      padding: 0 30px;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-top: 50px; // ẩn thanh fixed top
      // padding-top: 100px; // chừa chỗ cho thanh fixed top

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .menu {
        ul {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          margin-bottom: 0;

          li {
            margin: 0 15px;

            @media screen and (max-width: 1200px) {
              margin: 0 10px;
            }

            a {
              text-decoration: none;
              color: whitesmoke;
              font-weight: 600;
              font-size: 1rem;
              cursor: pointer;
              transition: all 0.25s ease;

              @media screen and (max-width: 1200px) {
                font-size: 0.9rem;
              }

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      .action {
        button {
          border: none;
          outline: none;
          padding: 8px 22px;
          margin-left: 10px;
          background: linear-gradient($primary-color-light 0%, $primary-color 100%);
          box-shadow: $button-shadow 2px 2px 20px -5px;
          border-radius: 100px;
          color: whitesmoke;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.25s ease;

          &:hover {
            box-shadow: $button-shadow 2px 2px 30px -5px;
            background: linear-gradient($primary-color 0%, $primary-color-light 100%);
          }
        }
      }
    }
  }

  .home-menu-mobile {
    position: relative;
    z-index: 5;

    .container {
      width: 100%;
      padding: 0 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-top: 20px; // ẩn thanh fixed top
      // padding-top: 75px; // có thanh fixed top

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .drawer {
        color: $primary-color;
        font-size: 25px;
      }
    }
  }

  .home-title {
    position: relative;
    z-index: 5;

    .container {
      width: 100%;
      max-width: 1400px;
      padding: 0 30px;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1500px) {
        padding-top: 0;
      }

      @media screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .right {
        width: 30%;
        text-align: center;

        @media screen and (max-width: 992px) {
          width: 100%;
          text-align: center;
        }

        img {
          height: 850px;

          @media screen and (max-width: 1500px) {
            height: 650px;
          }

          @media screen and (max-width: 992px) {
            height: 530px;
            margin-top: 50px;
          }

          @media screen and (max-width: 768px) {
            height: 380px;
            margin-top: 50px;
          }

          @media screen and (max-width: 450px) {
            height: 300px;
            margin-top: 30px;
          }
        }
      }

      .left {
        width: 70%;

        @media screen and (max-width: 992px) {
          width: 100%;
          margin-top: 50px;
          text-align: center;
        }

        h1 {
          color: $primary-color;
          font-weight: 800;
          font-size: 5rem;
          margin-bottom: 30px;

          @media screen and (max-width: 1200px) {
            font-size: 4.5rem;
            margin-bottom: 25px;
          }

          @media screen and (max-width: 992px) {
            font-size: 3rem;
            margin-bottom: 10px;
          }
        }

        h3 {
          color: whitesmoke;
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 70px;

          @media screen and (max-width: 1200px) {
            margin-bottom: 50px;
          }

          @media screen and (max-width: 992px) {
            font-size: 1.2rem;
            margin-bottom: 0px;
            padding: 0 100px;
          }

          @media screen and (max-width: 576px) {
            font-size: 15px;
            margin-bottom: 0px;
            padding: 0 10px;
          }
        }

        .download {
          a + a {
            margin-left: 20px;
          }

          img {
            width: auto;
            height: 50px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.home-header-on-other-page {
  width: 100%;
  height: 100vh;

  background-image: url(../../assets/img/home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  &::after {
    content: "";
    width: 100%;
    height: 100vh;

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(16, 18, 34, 1) 100%);
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  .home-menu {
    position: relative;
    z-index: 16;

    .container {
      width: 100%;
      max-width: 1400px;
      padding: 0 30px;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-top: 50px;

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .menu {
        ul {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          margin-bottom: 0;

          li {
            margin: 0 15px;

            @media screen and (max-width: 1200px) {
              margin: 0 10px;
            }

            a {
              text-decoration: none;
              color: whitesmoke;
              font-weight: 600;
              font-size: 1rem;
              cursor: pointer;
              transition: all 0.25s ease;

              @media screen and (max-width: 1200px) {
                font-size: 0.9rem;
              }

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      .action {
        button {
          border: none;
          outline: none;
          padding: 8px 22px;
          margin-left: 10px;
          background: linear-gradient($primary-color-light 0%, $primary-color 100%);
          box-shadow: $button-shadow 2px 2px 20px -5px;
          border-radius: 100px;
          color: whitesmoke;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.25s ease;

          &:hover {
            box-shadow: $button-shadow 2px 2px 30px -5px;
            background: linear-gradient($primary-color 0%, $primary-color-light 100%);
          }
        }
      }
    }
  }

  .home-menu-mobile {
    position: relative;
    z-index: 5;

    .container {
      width: 100%;
      padding: 0 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-top: 20px;

      .logo {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .drawer {
        color: $primary-color;
        font-size: 25px;
      }
    }
  }
}
