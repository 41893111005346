.postDetail-asd {
  max-width: 1200px;
  margin: 0 auto;

  margin-top: 50px;
  margin-bottom: 50px;

  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  padding: 30px;

  @media screen and (max-width: 992px) {
    border: none;
    padding: 20px;
    margin: 30px 10px;
  }

  background-color: whitesmoke;

  position: relative;
  z-index: 99;

  animation: fadeIn;
  animation-duration: 0.5s;

  .p-title {
    margin-bottom: 50px;

    .breadcrumb {
      margin-bottom: 15px;

      span {
        font-size: 14px;
        cursor: pointer;

        &:hover {
          font-weight: 500;
        }
      }
    }

    .title {
      font-family: "Fira Sans", sans-serif;
      font-size: 1.8rem;
      color: $primary-color;
      margin-bottom: 15px;

      @media screen and (max-width: 992px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 576px) {
        font-size: 1.3rem;
      }
    }

    .time {
      font-size: 12px;
      color: grey;
    }
  }

  .p-content {
    font-family: "Fira Sans", sans-serif !important;

    ul {
      padding-left: 30px;

      li + li {
        margin-top: 5px;
      }
    }

    img {
      max-width: 100%;
    }
  }
}
