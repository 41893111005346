@import "../../assets/scss/base.scss";

.news-page {
  animation: fadeIn;
  animation-duration: 0.5s;
}

.news-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 30px;
  position: relative;
  z-index: 99;

  .news-title {
    h2.title {
      font-weight: bold;
      font-size: 3.5rem;
      color: $primary-color;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
      }
    }
  }

  .news-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    .news-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 10px;
      padding: 15px;
      background-color: rgba(245, 245, 245, 0.05);
      transition: all 0.15s ease;
      cursor: pointer;

      &:hover {
        background-color: rgba(245, 245, 245, 0.1);
      }

      .post-image {
        width: 210px;
        min-width: 210px;
        height: 140px;
        margin-right: 30px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
          width: 180px;
          min-width: 180px;
          height: 120px;
          margin-right: 20px;
        }

        @media screen and (max-width: 576px) {
          width: 150px;
          min-width: 150px;
          height: 100px;
        }

        @media screen and (max-width: 450px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.15s ease;
          border: 1px solid grey;
          border-radius: 10px;
        }
      }

      .post-main {
        .post-title {
          color: white;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1.8;
          text-align: justify;
          text-justify: inter-word;
          margin-top: 10px;
          margin-bottom: 10px;

          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }

        .post-time {
          color: rgb(209, 209, 209);
          font-size: 12px;
          margin-bottom: 10px;
        }

        .post-content {
          color: whitesmoke;
          font-size: 1rem;
          line-height: 1.8;
          text-align: justify;
          text-justify: inter-word;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .pagination-box {
    margin-top: 20px;
    text-align: right;
  }
}

.overlay-bottom {
  width: 100%;
  height: 0; // height will be re-set in JS
  background-color: #101222;
}
