.inputPriceContainer {
    padding: 12px 16px;
    border: 1px solid rgb(234, 236, 239);
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    transition: all 0.3s ease-out;

    &:hover {
        border-color: #c4c4c4;
    }

    &.error {
        border-color: rgb(246, 70, 93);
    }

    &.disabled {
        cursor: not-allowed;
        background-color: #ebebeb;

        &:hover {
            border: 1px solid rgb(234, 236, 239);
        }

        input,
        label {
            cursor: not-allowed;
            background-color: #ebebeb;
        }
    }

    label {
        font-size: 13px;
        color: #212833;
        font-weight: 500;
        margin-bottom: 8px;
        display: block;
        line-height: 1.5;
    }

    .inputPrice {
        display: block;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        opacity: 1;
        max-width: 100px;
        line-height: 1.6;

        &.isFullWidth {
            max-width: 100%;
            width: 100%;
        }

        @media screen and (min-width: 425px) {
            max-width: 250px;

            &.isFullWidth {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    .iconCurrency {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-16px, -12px);
    }

    .topRightBlock {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-6px, 6px);
    }
}
