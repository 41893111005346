@import "./assets/scss/base.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

// Sweet Alert

.btn-swal-antd {
  border: none;
  outline: none;
  border-radius: 5px;

  padding: 6px 25px;

  background-color: $primary-color;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);

  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: $primary-color-light;
  }
}

.content-swal-antd {
  color: black;
  font-size: 1rem;
}

.title-swal-antd {
  font-size: 1.5rem;
}

.no-border {
  border: 0 !important;
}

// Toast

.swal2-popup.swal2-toast {
  padding: 10px 15px;

  .swal2-title {
    font-size: 14px;
    margin: 7px;
    font-weight: 500;
  }

  .no-border {
    border: 0 !important;
  }
}
