.purchasedPackageContainer {
    margin: 0 auto;
    padding: 20px;
    max-width: 600px;
    background-color: #ffffff;
    color: #000;
    border-radius: 12px;
    cursor: pointer;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    transition: all 0.3s ease-out;
    position: relative;
    margin-top: 30px;

    .titlePurchased {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1.25px;
        margin-bottom: 16px;
    }

    .infoPurchased {
        display: flex;
        gap: 12px;
        flex-direction: column;

        .row {
            display: flex;
            gap: 8px;
            letter-spacing: 0.5px;

            .label {
                font-weight: 600;
            }

            .value {
                word-break: break-all;
                max-width: 300px;
            }
        }
    }
}
