@import "../../assets/scss/base.scss";

.navbar {
  width: 100%;
  height: 60px;
  background: #101222;
  padding: 0 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 100%;
    color: $primary-color;
    display: flex;
    align-items: center;

    i {
      color: $primary-color;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.25s ease;

      &:hover {
        color: whitesmoke;
      }
    }

    img {
      width: 48px;
      height: 40px;
      margin-left: 20px;
    }
  }

  .action {
    i {
      color: $primary-color;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.25s ease;

      &:hover {
        color: whitesmoke;
      }
    }
  }
}
