@import "../../assets/scss/base.scss";

.admin-writter {
  .field + .field {
    margin-top: 16px;
  }

  .img-preview {
    width: auto;
    height: 100px;
  }

  .action-area {
    margin-top: 25px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.writter-image-chooser-drawer {
  .list {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .image-wrapper {
      width: 100%;
      height: 100px;
      border: 1px solid grey;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 0.15s ease;
      }

      &:hover {
        border-color: $primary-color;
        border-width: 3px;
      }
    }
  }
}
