.step2Container {
    .btnsStep2 {
        margin: 40px auto 0;
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;

        button {
            max-width: 250px;
            width: 100%;
        }
    }
}
