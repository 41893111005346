.admin-media {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;

  .media-upload,
  .media-list {
    .title-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 25px;
    }

    .pagination-box {
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 25px;
        text-align: right;
      }
    }

    form {
      @media screen and (max-width: 576px) {
        text-align: center;
      }

      .photo-field {
        input {
          width: 100%;
        }
      }

      .img-preview-wrapper {
        margin-top: 20px;
      }

      .img-preview {
        width: auto;
        height: 120px;

        @media screen and (max-width: 576px) {
          height: 100px;
        }
      }
    }
  }

  .media-list {
    .list {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      gap: 15px;

      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .image-wrapper {
        width: 100%;
        height: 100px;
        border: 1px solid grey;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.15s ease;
        }

        &:hover img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.image-modal {
  img {
    width: 50%;
    height: auto;
  }

  .image-name {
    margin-top: 20px;
  }
  .image-time {
    margin-top: 10px;
  }
}
