@import "../../assets/scss/base.scss";

.home-page {
  overflow-x: hidden;

  animation: fadeIn;
  animation-duration: 0.5s;
}

.home-number {
  width: 100%;
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 140px;
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (max-width: 576px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    h2.title {
      color: whitesmoke;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 50px;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .item {
        width: 100%;
        border-radius: 15px;
        padding: 25px;
        position: relative;
        box-shadow: rgba(96, 96, 96, 0.9) 0px 5px 34px inset;

        &::before {
          content: "";
          position: absolute;
          inset: 0px;
          border-radius: 15px;
          padding: 2px;
          background: linear-gradient(
            100.01deg,
            $primary-color 5.41%,
            rgba(255, 138, 52, 0) 32.38%,
            rgba(255, 138, 52, 0) 65.19%,
            $primary-color 89.79%
          );
          -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box,
            linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
          -webkit-mask-composite: xor;
        }

        div:nth-child(1) {
          font-size: 2.5rem;
          text-align: center;
          font-weight: bold;
          color: white;
          margin-bottom: 18px;

          @media screen and (max-width: 992px) {
            font-size: 1.5rem;
          }
        }

        div:nth-child(2) {
          font-size: 1.1rem;
          text-align: center;
          font-weight: 600;
          color: $primary-color;
        }
      }
    }
  }
}

// .home-data-fixed {
//   width: 100%;
//   height: 75px;
//   max-width: 1150px;
//   margin-inline: auto;

//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: center;

//   background: linear-gradient(#222539 0%, #101222 100%);
//   border-radius: 16px;
//   position: fixed;
//   top: 15px;
//   left: 50%;
//   transform: translateX(-50%);

//   z-index: 100;

//   @media screen and (max-width: 1200px) {
//     top: 10px;
//     height: 70px;
//     width: 90%;
//     max-width: 1000px;
//   }
//   @media screen and (max-width: 992px) {
//     height: 55px;
//   }

//   // @media screen and (max-width: 768px) {
//   //   display: flex;
//   //   flex-direction: column;
//   //   justify-content: space-around;
//   //   align-items: center;
//   // }

//   .data1 {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

//     span {
//       color: $primary-color;
//       display: inline-block;
//       margin-right: 7px;
//       font-weight: 600;
//       font-size: 22px;

//       @media screen and (max-width: 1200px) {
//         font-size: 20px;
//       }
//       @media screen and (max-width: 992px) {
//         font-size: 16px;
//       }
//       @media screen and (max-width: 576px) {
//         font-size: 14px;
//         margin-right: 5px;
//       }
//     }

//     div {
//       color: white;
//       display: inline-block;
//       font-weight: 600;
//       font-size: 22px;

//       @media screen and (max-width: 1200px) {
//         font-size: 20px;
//       }
//       @media screen and (max-width: 992px) {
//         font-size: 16px;
//       }
//       @media screen and (max-width: 576px) {
//         font-size: 14px;
//       }
//     }
//   }

//   .data2 {
//     a {
//       border: none;
//       outline: none;
//       padding: 12px 50px;
//       background: linear-gradient($primary-color-light 0%, $primary-color 100%);
//       box-shadow: $button-shadow 2px 2px 20px -5px;
//       border-radius: 100px;
//       color: #222539;
//       font-weight: bold;
//       font-size: 20px;
//       cursor: pointer;
//       transition: all 0.15s ease;

//       &:hover {
//         box-shadow: $button-shadow 2px 2px 30px -5px;
//         background: linear-gradient($primary-color 0%, $primary-color-light 100%);
//       }

//       @media screen and (max-width: 1200px) {
//         font-size: 18px;
//         padding: 10px 40px;
//       }
//       @media screen and (max-width: 992px) {
//         font-size: 16px;
//         padding: 8px 35px;
//       }
//       @media screen and (max-width: 576px) {
//         font-size: 13px;
//         padding: 5px 18px;
//       }
//     }
//   }
// }

// .home-data-layer {
//   width: 100%;
//   max-width: 1154px;
//   margin-inline: auto;
//   height: 79px;
//   background: rgb(245, 245, 245);
//   background: linear-gradient(
//     90deg,
//     rgba(245, 245, 245, 1) 0%,
//     rgba(34, 37, 57, 0.9) 40%,
//     rgba(34, 37, 57, 0.9) 60%,
//     rgba(245, 245, 245, 1) 100%
//   );
//   border-radius: 16px;

//   position: fixed;
//   top: 13px;
//   left: 50%;
//   transform: translateX(-50%);

//   z-index: 99;

//   @media screen and (max-width: 1200px) {
//     top: 9px;
//     height: 72px;
//     width: 90%;
//     max-width: 1002px;
//   }
//   @media screen and (max-width: 992px) {
//     height: 57px;
//   }
// }

.home-data {
  width: 100%;
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    padding-top: 70px;

    @media screen and (max-width: 992px) {
      padding-top: 120px;
    }

    @media screen and (max-width: 768px) {
      padding-top: 180px;
    }

    @media screen and (max-width: 576px) {
      padding-top: 150px;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .item + .item {
      margin-left: 20px;
    }

    .item {
      color: whitesmoke;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      margin: 0px 0px 32px;
      padding: 24px 0px;
      background: rgba(105, 178, 11, 0.1);
      box-shadow: rgba(255, 255, 255, 0.25) 0px 5px 34px inset;
      border-radius: 18.8475px;
      position: relative;

      span.title {
        color: whitesmoke;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 8px;

        @media screen and (max-width: 992px) {
          font-size: 24px;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
        @media screen and (max-width: 576px) {
          font-size: 18px;
        }
        @media screen and (max-width: 420px) {
          font-size: 15px;
        }
      }

      span.content {
        color: rgb(105, 178, 11);
        font-weight: bold;
        font-size: 35px;

        @media screen and (max-width: 992px) {
          font-size: 28px;
        }
        @media screen and (max-width: 768px) {
          font-size: 26px;
        }
        @media screen and (max-width: 576px) {
          font-size: 22px;
        }
      }
    }

    .item::before {
      content: "";
      position: absolute;
      inset: 0px;
      border-radius: 18.8475px;
      padding: 2px;
      background: linear-gradient(
        100.01deg,
        rgb(105, 178, 11) 5.41%,
        rgba(105, 178, 11, 0) 32.38%,
        rgba(105, 178, 11, 0) 65.19%,
        rgb(105, 178, 11) 89.79%
      );
      mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box,
        linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box,
        linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      mask-composite: xor;
      -webkit-mask-composite: xor;
    }
  }
}

.home-banner {
  width: 100%;
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    padding-top: 100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .left {
      width: 100%;

      @media screen and (max-width: 576px) {
        width: 100%;
      }

      .left1,
      .left2 {
        font-weight: bold;
        font-size: 3.5rem;
        color: whitesmoke;

        @media screen and (max-width: 992px) {
          font-size: 2rem;
        }

        span {
          color: $primary-color;
        }
      }

      .left2 {
        margin-bottom: 30px;

        @media screen and (max-width: 992px) {
          margin-bottom: 10px;
        }
      }

      .left3 {
        color: white;
        font-size: 1.2rem;
        margin: 50px 0;

        @media screen and (max-width: 992px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 576px) {
          margin: 25px 0;
        }
      }

      .download {
        margin-bottom: 30px;

        a + a {
          margin-left: 20px;
        }

        img {
          width: auto;
          height: 50px;
          cursor: pointer;

          @media screen and (max-width: 992px) {
            height: 40px;
          }
        }
      }

      button {
        border: none;
        outline: none;
        padding: 8px 22px;
        background: linear-gradient($primary-color-light 0%, $primary-color 100%);
        box-shadow: $button-shadow 2px 2px 20px -5px;
        border-radius: 100px;
        color: whitesmoke;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.25s ease;

        &:hover {
          box-shadow: $button-shadow 2px 2px 30px -5px;
          background: linear-gradient($primary-color 0%, $primary-color-light 100%);
        }
      }
    }
  }
}

.home-howtoplay {
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 30px;

    position: relative;
    z-index: 2;

    h2.title {
      color: whitesmoke;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 50px;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
      }
    }

    p {
      color: whitesmoke;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;
      padding: 0 200px;

      @media screen and (max-width: 992px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 768px) {
        padding: 0 100px;
      }

      @media screen and (max-width: 576px) {
        padding: 0 50px;
      }

      @media screen and (max-width: 450px) {
        padding: 0;
      }
    }

    .download {
      margin-top: 80px;
      text-align: center;

      img {
        cursor: pointer;
        height: 50px;

        @media screen and (max-width: 992px) {
          height: 40px;
        }
      }

      a + a {
        margin-left: 20px;
      }
    }

    .circle {
      width: 350px;
      height: 350px;
      background-color: $primary-color;
      border-radius: 10%;
      z-index: -1;
      filter: blur(300px);

      position: absolute;
      top: 0px;
      left: 200px;
    }
  }
}

.home-piechart {
  width: 100%;
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    padding-top: 70px;
  }

  h2.title {
    color: whitesmoke;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 30px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 992px) {
      font-size: 2rem;
      padding-bottom: 20px;
    }
  }

  #pie-chart-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    #pie-chart-container {
      width: 500px;
      height: 500px;
      @media screen and (max-width: 992px) {
        width: 400px;
        height: 400px;
      }
      @media screen and (max-width: 768px) {
        width: 350px;
        height: 350px;
      }
      @media screen and (max-width: 576px) {
        width: 280px;
        height: 280px;
      }

      position: relative;

      .blur-item {
        position: absolute;
        z-index: 1;
        top: -50px;
        right: -100px;
        width: 200px;
        height: 200px;
        background-color: rgb(105, 178, 11);
        filter: blur(150px);
      }

      .chart-item {
        position: relative;
        z-index: 2;
      }
    }
  }
}

.home-roadmap {
  background-color: #101222;

  .overlay1 {
    background-image: url(../../assets/img/home.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .overlay2 {
    background: rgb(16, 18, 34);
    background: linear-gradient(
      0deg,
      rgba(16, 18, 34, 1) 0%,
      rgba(16, 18, 34, 0.8) 30%,
      rgba(16, 18, 34, 0.8) 70%,
      rgba(16, 18, 34, 1) 100%
    );
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    padding: 100px 30px;

    @media screen and (max-width: 992px) {
      padding: 50px 30px;
    }

    h2.title {
      color: whitesmoke;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 70px;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
        margin-bottom: 40px;
      }
    }

    .vted {
      color: white;
      font-weight: 600;

      @media screen and (max-width: 992px) {
        color: black;
      }
    }
  }
}

.home-team {
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 30px;

    h2.title {
      color: whitesmoke;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 20px;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
      }
    }

    h3.subtitle {
      color: rgb(210, 210, 210);
      font-size: 1.1rem;
      text-align: center;
      margin-bottom: 100px;
      padding: 0 200px;

      @media screen and (max-width: 768px) {
        padding: 0 100px;
      }

      @media screen and (max-width: 768px) {
        padding: 0 50px;
      }

      @media screen and (max-width: 576px) {
        padding: 0 20px;
      }

      @media screen and (max-width: 450px) {
        padding: 0;
        font-size: 1rem;
        margin-bottom: 50px;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      max-width: 100%;
      flex-wrap: wrap;

      .item {
        width: 150px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        margin: 10px 20px;

        @media screen and (max-width: 768px) {
          width: 125px;
          margin: 10px 15px;
        }

        img {
          width: 150px;
          height: 150px;
          min-width: 150px;
          min-height: 150px;
          border-radius: 50%;
          margin-bottom: 20px;

          @media screen and (max-width: 768px) {
            width: 125px;
            height: 125px;
            min-width: 125px;
            min-height: 125px;
          }
        }

        p {
          font-weight: 600;
          font-size: 1rem;
          color: $primary-color;
          margin-bottom: 8px;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        span {
          color: whitesmoke;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.home-partner {
  background-color: #101222;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 30px;

    @media screen and (max-width: 992px) {
      padding: 30px;
    }

    @media screen and (max-width: 576px) {
      padding: 30px 0;
    }

    h2.title {
      color: whitesmoke;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 40px;

      @media screen and (max-width: 992px) {
        font-size: 2rem;
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 50px;
      align-items: center;

      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        justify-content: center;
        align-items: center;
        padding: 0 80px;
      }

      @media screen and (max-width: 576px) {
        padding: 0 10px;
        column-gap: 10px;
        row-gap: 30px;
      }

      img {
        width: 100%;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.home-footer {
  background-color: #141939;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    color: whitesmoke;

    .item1 {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100px;
        margin-bottom: 20px;
      }

      span {
        display: inline-block;
        font-size: 12px;
      }
    }

    .item2 {
      @media screen and (max-width: 576px) {
        margin-top: 30px;
        text-align: center;
      }

      span {
        display: block;
        margin-bottom: 12px;
      }
    }

    .item3 {
      a {
        display: inline-block;
        margin-bottom: 12px;
        text-decoration: none;
        color: $primary-color;

        @media screen and (max-width: 450px) {
          display: block;
        }
      }

      @media screen and (max-width: 576px) {
        margin-top: 30px;
        text-align: center;
      }

      a + a {
        margin-left: 22px;

        @media screen and (max-width: 450px) {
          margin-left: 0;
        }
      }
    }

    .item4 {
      @media screen and (max-width: 576px) {
        margin-top: 20px;
      }

      i {
        width: 50px;
        height: 50px;
        background-color: whitesmoke;
        color: #101222;
        border-radius: 50%;
        text-align: center;
        font-size: 22px;
        line-height: 50px;
        cursor: pointer;
        transition: all 0.25s ease;

        @media screen and (max-width: 992px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
        }

        &:hover {
          background-color: $primary-color;
          color: whitesmoke;
        }
      }

      a {
        display: inline-block;
      }

      a + a {
        margin-left: 10px;
      }
    }
  }

  .container2 {
    width: 100%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #101222;

    text-align: center;
    color: whitesmoke;
  }
}

.homeDrawer {
  .ant-drawer-content {
    background-color: #101222;
  }

  .menu {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        margin: 15px 0;

        a {
          text-decoration: none;
          color: whitesmoke;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.25s ease;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  .action-mobile {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      border: none;
      outline: none;
      padding: 8px 22px;
      margin-bottom: 20px;
      background: linear-gradient($primary-color-light 0%, $primary-color 100%);
      box-shadow: $button-shadow 2px 2px 20px -5px;
      border-radius: 100px;
      color: whitesmoke;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.25s ease;

      &:hover {
        box-shadow: $button-shadow 2px 2px 30px -5px;
        background: linear-gradient($primary-color 0%, $primary-color-light 100%);
      }
    }
  }
}

.home-totop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;

  background-color: #f5f5f550;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: $primary-color;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: $primary-color;
    color: whitesmoke;
  }

  @media screen and (max-width: 450px) {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
  }
}
